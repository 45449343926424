<template>
  <div class="Type3Select-height">
    <div class="Type3Select Type3Select-height">
      <van-row v-if="!is_select_input">
        <van-col span="14">
          <van-dropdown-menu>
            <van-dropdown-item v-model="selectType"
                               @change="selectEdit"
                               :options="selectList"
                               v-if="hideBtn.indexOf('xiaLa')==-1"
            />
          </van-dropdown-menu>
        </van-col>
        <van-col span="5" class="tu-biao">
          <i class="iconfont iconshaixuan1"
             @click="lou_dou"
            v-if="hideBtn.indexOf('louDou')==-1"
          />
        </van-col>
        <van-col span="5" class="tu-biao">
          <i class="iconfont iconsousuo"
             v-if="hideBtn.indexOf('souSuo')==-1"
             @click="is_select_input = true"
          ></i>
        </van-col>
      </van-row>
      <form action="/" v-if="is_select_input">
        <van-search
            v-model="selectValue"
            show-action
            @clear="onSearch"
            :placeholder="inputTitle"
            @search="onSearch"
            @cancel="onCancel"
        />
      </form>
    </div>
  </div>
</template>

<script >
import {Options, Vue} from 'vue-class-component';

/**
 * 三个选择的输入框
 */
@Options({
  props: {
    hideBtn:{
      type:Array,
      default(){
        //类型有：xiaLa、louDou、souSuo
        return []
      }
    },//配置隐藏哪些按钮
    selectList: { //配置下拉
      type: Array,
      default() {
        return [
          {text: '全部商品', value: 0},
          {text: '新款商品', value: 1},
          {text: '活动商品', value: 2},
        ]
      }
    },
    //配置搜索数据库提示的关键词
    inputTitle: {
      type: String,
      default() {
        return "请输入搜索关键词"
      }
    }
  },
  data() {
    return {
      selectType: this.selectList[0]['value'],//下啦选择的数据
      selectList: this.selectList, //下拉列表
      is_select_input: false,//是否显示搜索的输入框
      selectValue: "",//输入的内容
    }
  },

  methods: {
    lou_dou() {
      this.$emit("louDouEvent")//触发漏斗事件
    },
    //点击 执行搜索
    onSearch() {
      this.$emit("select",this.selectValue)//出发关键词搜索事件
    },
    //点击 取消搜索
    onCancel() {
      this.is_select_input = false
    },
    //下拉数据改变事件
    selectEdit() {
      this.$emit("selectType", this.selectType)
    }
  }
})
export default class Type3Select extends Vue {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="less" scoped>

.tu-biao {
  text-align: center;
  line-height: 50px;
}

.tu-biao > i {
  font-size: 20px;
}

.Type3Select {
  background: #fff;
  position: fixed;
  width: 100%;
  top:0;
  left: 0;
  z-index: 1;
  border-bottom: 1px solid #eee;
}

.Type3Select-height {
  height: 49px;
}
</style>
